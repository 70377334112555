import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Main/index.tsx";
import { HighlightCardWithIcon } from '@/components/HighlightCard';
import Provider, { BlueText } from '@/components/Solutions/Provider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import AutomatedDecisioningIcon from '@/assets/solutions/automated-decisioning.webp';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Provider mdxType="Provider">
      <Box align="center" paddingY={3} mdxType="Box">
        <h4 {...{
          "id": "the-keys-to-success-lie-in-digital-transformation",
          "style": {
            "position": "relative"
          }
        }}>{`The keys to success lie in digital transformation.`}<a parentName="h4" {...{
            "href": "#the-keys-to-success-lie-in-digital-transformation",
            "aria-label": "the keys to success lie in digital transformation permalink",
            "className": "anchor after"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a></h4>
      </Box>
      <Box align="left" mdxType="Box">
TaleFin is committed to process automation, ensuring lower costs and higher margins. The service offered is in real-time and extremely fast allowing an improved experience with dynamic customer journeys. Automated Decisioning brings many benefits. It relieves loan processers of most of their routine tasks and streamlines the approval process by preventing long, cumbersome paper chains and increasing efficiency.
      </Box>
      <Box padding={5} mdxType="Box">
        <HighlightCardWithIcon icon={AutomatedDecisioningIcon} mdxType="HighlightCardWithIcon">
          <p>{`Automation slashes the time required to process an application and makes decisions more objective, traceable, and transparent. It also removes human error. As a result, financial institutions can introduce almost 100 percent automation of complex lending and decision-making processes, including bank statement assessment and risk assessment while meeting regulatory obligations and increasing customer application speed, and improving the overall customer journey.`}</p>
          <p>{`Automation is important and necessary for organisations and companies as it improves productivity by reducing time and increasing efficiency. Repetitive tasks are automated and all the tasks are done the same way. This allows quality to remain consistent all the time.`}</p>
        </HighlightCardWithIcon>
      </Box>
      <Box align="center" mdxType="Box">
        <BlueText variant="h5" mdxType="BlueText">
  Find out how our Automated Decisioning can benefit your business.
        </BlueText>
      </Box>
    </Provider>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      